import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import {getLang} from "../../../../utils/getLang";


import "./more.less";


const HomeMore = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  useEffect(() => {

  }, []);



  useEffect(() => {
  }, []);

  return (
      <div className="intromore-container">
        <div className="intromore-card">
          <h2 className="intromore-title">{t('LearnMore')}</h2>
          <div className="intromore-buttons">
            <button className="intromore-btn intromore-btn-primary" onClick={()=>{window.location.href = "https://app.openas.ai"}}>{t('LaunchAPP')}</button>
            <button className="intromore-btn intromore-btn-secondary" onClick={()=>{window.location.href = "https://app.openas.ai/finance"}}>{t('RealAssets')}</button>
          </div>
        </div>
        <div className="intromore-divider"></div>
      </div>
  );
};

export default HomeMore;
