import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

import "./homeabout.less";
import Button from "../../../../Components/Button/Button";



const HomAboutContent = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  useEffect(() => {

  }, []);


  const [landingPageData, setLandingPageData] = useState({})



  return (
      <div className="homeabout-wrapper">
        <div className="homeabout-container">
          <div className="homeabout-title">
            {t('AboutUs')}
          </div>
          <div className="homeabout-btncontainer">
            <Button onClick={() => {
              window.open('https://openas.gitbook.io/openas', '_blank');
            }} style={{width:150,background:'#fff',color:'#005114',borderRadius:10}}>{t('Docs')} 》</Button>
            <Button onClick={() => {
              let pdfPath = `${window.location.origin}/EN-OPENAS.pdf`;
              let lang = localStorage.getItem("lang");
              if(lang == 'cn'){
                pdfPath = `${window.location.origin}/ZH-OPENAS.pdf`;
              }
              window.open(pdfPath, '_blank');
            }} style={{width:150,background:'#fff',color:'#005114',borderRadius:10}}>{t('WhitePaper')} 》</Button>
          </div>
        </div>
      </div>
  );
};

export default HomAboutContent;
