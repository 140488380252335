import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import proido from "@/assets/img/h/pro_ido.png";
import proabout from "@/assets/img/h/pro_about.png";
import procontact from "@/assets/img/h/pro_contact.png";
import pronft from "@/assets/img/h/pro_nft.png";
import proreal from "@/assets/img/h/pro_real.png";
import prostake from "@/assets/img/h/pro_stake.png";

import "./homeproduct.less";



const HomProductContent = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  useEffect(() => {

  }, []);

  const products = [
    {
      logo: proido,
      name: t('product_name_1'),
      description: t('product_desc_1'),
      link: '/ido',
      isHighlight: true
    },
    {
      logo: proreal,
      name: t('product_name_2'),
      description: t('product_desc_2'),
      link: '/finance',
      isHighlight: false
    },
    {
      logo: pronft,
      name: t('product_name_3'),
      description: t('product_desc_3'),
      link: '/nftmint',
      isHighlight: false
    },
    {
      logo: proabout,
      name: t('product_name_4'),
      description: t('product_desc_4'),
      link: 'https://openas.gitbook.io/openas',
      isHighlight: false
    },
    {
      logo: procontact,
      name: t('product_name_5'),
      description: t('product_desc_5'),
      link: 'http://t.me/OpenAs_Global',
      isHighlight: false
    },
    {
      logo: prostake,
      name: t('product_name_6'),
      description: t('product_desc_6'),
      link: '/finance',
      isHighlight: false
    }
  ];



  return (
    <div className="homeintro-wrapper">
      <div className="homeintro-container">
        <h1 className="homeintro-title">{t('Home_product')}</h1>
        <div className="homeintro-grid">
          {products.map((product, index) => (
              <a
                  key={index}
                  href={product.link}
                  className={`homeintro-card ${product.isHighlight ? 'homeintro-card-highlight' : ''}`}
              >
                <img src={product.logo} alt={product.name} className="homeintro-logo" />
                <div className="homeintro-content">
                  <h3 className="homeintro-name">{product.name}</h3>
                  <p className="homeintro-description">{product.description}</p>
                  <span className="homeintro-check">Check »</span>
                </div>
              </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomProductContent;
