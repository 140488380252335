import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import {getLang} from "../../../../utils/getLang";
import topmap from "@/assets/img/intro/top_map.png";
import nftpic from "@/assets/img/intro/nft_1.png";
import launchimg from "@/assets/img/common/launch.png";

import "./top.less";
import ButtonWithImg from "../../../../Components/Button/ButtonWithImg";
import ButtonFlat from "../../../../Components/Button/ButtonFlat";


// @ts-ignore
const Button = ({ children, variant = 'default', icon, className = '', ...props }) => {
  return (
      <button
          className={`
        px-6 py-3 
        rounded-full
        text-base
        font-medium
        transition-all
        duration-200
        ${variant === 'outline'
              ? 'border-2 border-green-500 text-green-500 hover:bg-green-50'
              : 'bg-green-500 text-white hover:bg-green-600'
          }
        ${className}
      `}
          {...props}
      >
        <div className="flex items-center justify-center gap-2">
          {icon && <img src={icon} alt="" className="w-5 h-5" />}
          {children}
        </div>
      </button>
  );
};

const HomeTop = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  useEffect(() => {

  }, []);



  useEffect(() => {
  }, []);

  return (
    <div className="introtop-wrapper">
      <div className="introtop-container">
        <div className="introtop-area1">
          <div className="introtop-title">{t('Intro_top')}</div>
          <div className="introtop-btns">
            <ButtonWithImg icon={launchimg} style={{width:160,height:50}} onClick={()=>{window.location.href = "https://app.openas.ai"}}>{t('LaunchAPP')}</ButtonWithImg>
            <ButtonFlat onClick={()=>{window.location.href = "https://app.openas.ai/finance"}} style={{width:160,height:50,marginLeft:20}}>{t('RealAssets')}</ButtonFlat>
          </div>
        </div>
        {isMobile?<div/>:
            <div className="introtop-area2">
              <img className="introtop-map" src={topmap}></img>
              <div className="introtop-card">
                <div className="card-image">
                  <img
                      src={nftpic}
                      alt={'title'}
                  />
                </div>
                <div className="card-content">
                  <h2 className="card-title">OPASS#0173</h2>
                  <p className="card-subtitle">{t('intro_nft_title_1')}</p>
                  <div className="card-link">
                    <span>Check</span>
                    <svg
                        width="16"
                        height="16"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
        }

      </div>
    </div>
  );
};

export default HomeTop;
