import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useTranslation } from "react-i18next";
import { message, Modal } from "antd";
import enUS from "antd/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "./navbar.less";
import { getSignInInfo, getSignIn } from "../requests/index.js";
import { getLang } from "../utils/getLang";

import moreIcon from "@/assets/img/header/more.png";
import headerBon from "@/assets/img/header/header_bon.png";
import gou from "@/assets/img/header/gou.png";
import home from "@/assets/img/header/home.png";
import menuFinance from "@/assets/img/header/menu_finance.png"
import menuIdo from "@/assets/img/header/menu_ido.png"
import menuNft from "@/assets/img/header/menu_nft.png"
import menuRealestate from "@/assets/img/header/menu_realestate.png"
import menuLanguage from "@/assets/img/header/menu_language.png"

import whitepaper from "@/assets/img/header/whitepaper.png";
import shoppingmall from "@/assets/img/header/shoppingmall.png";
import linkwallet from "@/assets/img/header/linkwallet.png";
import login from "@/assets/img/header/login.png";
import download from "@/assets/img/header/download.png";
import line from "@/assets/img/header/line.png";
import logoImg from "@/assets/img/header/logo.png";
import langImg from "@/assets/img/header/lang.png";
import CIS from "@/assets/img/header/Checked_in_Successfully.png";
import CISCN from "@/assets/img/cnimg/Checked_in_SuccessfullyCn.png";
import juanone from "@/assets/img/header/juanone.png";
import juantwo from "@/assets/img/header/juantwo.png";
import my from "@/assets/img/header/my.png";
import langicon from "@/assets/img/header/langicon.png";
import logoone from "@/assets/img/header/logoone.png";
import close from "@/assets/img/home/close.png";

dayjs.locale("en");

const PLANT_TYPE = process.env.REACT_APP_PLANT_TYPE;
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const { address, isConnected } = useAccount();
  const lang = getLang();

  const [locale, setLocal] = useState(enUS);
  const changeLocale = (e: any) => {
    const localeValue = e.target.value;
    setLocal(localeValue);
    if (!localeValue) {
      dayjs.locale("en");
    } else {
      dayjs.locale("zh-cn");
    }
  };
  const onWaitAlert = () => {
    message.error({
      icon: (
        <img
          src={close}
          alt=""
          style={{ width: "20px", marginRight: "10px" }}
        />
      ),
      content: "Coming soon",
    });
  };

  const langList = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "cn",
      label: "繁體中文",
    },
    {
      value: "ja",
      label: "日本語",
    },
    {
      value: "ko",
      label: "한국어",
    },
    {
      value: "ru",
      label: "Русский",
    },
    {
      value: "th",
      label: "ไทย",
    },
    {
      value: "vi",
      label: "Tiếng Việt",
    },
    {
      value: "km",
      label: "ភាសាខ្មែរ",  // 柬埔寨语
    },
    {
      value: "my",
      label: "မြန်မာစာ",  // 缅甸语
    },
    {
      value: "lo",
      label: "ພາສາລາວ",  // 老挝语
    }
  ];

  const [acLang, setAcLang] = useState(localStorage.getItem("lang") || "en");
  const [isShowLang, setIsShowLang] = useState(false);

  const [isShowMenu, setIsShowMenu] = useState(false);

  const switchLang = (val: string) => {
    i18n.changeLanguage(val);
    setAcLang(val);
    setIsShowLang(false);
    localStorage.setItem("lang", val);
    // window.location.reload();
  };

  // 菜单点击
  const menuHandler = () => {
    setIsShowMenu(false);
  };

  // 签到内容
  const [isShowCheckIn, setIsShowCheckIn] = useState(false);
  const [checkInNum, setCheckInNum] = useState(0);


  const checkInCancel = () => {
    setIsShowCheckIn(false);
  };
  const afterOpenChange = (open: boolean) => {
    if (!open) {
      document.body.classList.remove("modal_open");
    }
  };
  // 是否签到

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // 回到页面顶部
  const homeTop = () => {
    if (pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  // 选中的页面 / 首页 /shop 商城页面
  const [selectPage, setSelectPage] = useState("/");

  useEffect(() => {
    setSelectPage(pathname);
  }, [pathname]);

  // 滚动事件
  const handleScroll = () => {
    setIsShowMenu(false);
  };
  useEffect(() => {
    if (isShowMenu) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isShowMenu]);

  return (
    <div className="header">
      <div className="header_top">
        <Link to="/" className="logo" onClick={homeTop}>
          <img src={logoImg} alt="" className="logo_pc" />
          <img src={logoone} alt="" className="logo_mobile" />
        </Link>
        <div className="header_left">
          <div className="header_link">
            <Link
              to="/"
              replace={false}
              onClick={() => setSelectPage("/")}
              className={selectPage === "/" ? "active_link" : ""}
            >
              <div className="name">{t("Home")}</div>
            </Link>
            <Link
                to="/ido"
                replace={false}
                onClick={() => setSelectPage("/ido")}
                className={selectPage === "/ido" ? "active_link" : ""}
            >
              <div className="name">{t("Ido")}</div>
            </Link>
            <Link
                to="/finance"
                replace={false}
                onClick={() => setSelectPage("/finance")}
                className={selectPage === "/finance" ? "active_link" : ""}
            >
              <div className="name">{t("Finance")}</div>
            </Link>
            <Link
                to="/nftmint"
                replace={false}
                onClick={() => setSelectPage("/nft")}
                className={selectPage === "/nftmint" ? "active_link" : ""}
            >
              <div className="name">{t("NFT")}</div>
            </Link>
              <a href="https://Jpz.phk.city" target="_blank" rel="noopener noreferrer">
                <div className="name">{t("META")}</div>
              </a>
            <Link
                to="/lending"
                replace={false}
                onClick={() => setSelectPage("/lending")}
                className={selectPage === "/lending" ? "active_link" : ""}
            >
              <div className="name">{t("Lending")}</div>
            </Link>
            <Link
                to="/dao"
                replace={false}
                onClick={() => setSelectPage("/dao")}
                className={selectPage === "/dao" ? "active_link" : ""}
            >
              <div className="name">{t("DAO")}</div>
            </Link>
          </div>
          <ConnectButton label={t("Connect Wallet")} />
          <div
            className="lang_box_pc"
            onMouseEnter={() => setIsShowLang(true)}
            onMouseLeave={() => setIsShowLang(false)}
          >
            <div className="lang_box_item">
              <div className="lang" onClick={() => setIsShowLang(true)}>
                <img src={langicon} alt="" />
              </div>
            </div>
            <ul
              className={
                isShowLang
                  ? "lang_list lang_list_show"
                  : "lang_list lang_list_hide"
              }
            >
              {langList.map((item) => (
                <li
                  key={item.value}
                  className="lang_list_item"
                  onClick={() => switchLang(item.value)}
                >
                  {item.label}
                  <img
                    src={gou}
                    alt=""
                    className={
                      acLang === item.value
                        ? "lang_list_item_img_active"
                        : "lang_list_item_img"
                    }
                  />
                </li>
              ))}
            </ul>
          </div>

          <div
            className="more"
            onMouseEnter={() => setIsShowMenu(true)}
            onMouseLeave={() => setIsShowMenu(false)}
          >
            <img src={moreIcon} alt="" onClick={() => setIsShowMenu(true)} />
            <ul
              className={
                isShowMenu
                  ? "menu_list menu_list_show"
                  : "menu_list menu_list_hide"
              }
            >
              {/* 此次如果要跳转外部链接，使用a标签 */}
              <li onClick={menuHandler}>
                <Link to="/" replace={false}>
                  <img src={home} alt="" />
                  <div className="name">{t("Home")}</div>
                </Link>
              </li>
              <div className='line'/>
              <li onClick={menuHandler}>
                <Link to="/ido" replace={false}>
                  <img src={menuIdo} alt="" />
                  <div className="name">{t("Ido")}</div>
                </Link>
              </li>
              <li onClick={menuHandler}>
                <Link to="/finance" replace={false}>
                  <img src={menuFinance} alt="" />
                  <div className="name">{t("Finance")}</div>
                </Link>
              </li>
              <li onClick={menuHandler}>
                <Link to="/nftmint" replace={false}>
                  <img src={menuRealestate} alt="" />
                  <div className="name">{t("NFT")}</div>
                </Link>
              </li>
              <li onClick={menuHandler}>
                <a href="https://Jpz.phk.city" target="_blank" rel="noopener noreferrer">
                  <img src={menuRealestate} alt="" />
                  <div className="name">{t("META")}</div>
                </a>
              </li>
              <li onClick={menuHandler}>
                <Link to="/lending" replace={false}>
                  <img src={menuRealestate} alt="" />
                  <div className="name">{t("Lending")}</div>
                </Link>
              </li>
              <li onClick={menuHandler}>
                <Link to="/dao" replace={false}>
                  <img src={menuNft} alt="" />
                  <div className="name">{t("DAO")}</div>
                </Link>
              </li>
              <li
                className="lang_box"
                onMouseEnter={() => setIsShowLang(true)}
                onMouseLeave={() => setIsShowLang(false)}
              >
                <div className="lang_box_item">
                  <img src={menuLanguage} alt="" />
                  <div className="lang" onClick={() => setIsShowLang(true)}>
                    <span>{t("Switch language")}</span>
                  </div>
                </div>
                <ul
                  className={
                    isShowLang
                      ? "lang_list lang_list_show"
                      : "lang_list lang_list_hide"
                  }
                >
                  {langList.map((item) => (
                    <li
                      key={item.value}
                      className="lang_list_item"
                      onClick={() => switchLang(item.value)}
                    >
                      {item.label}
                      <img
                        src={gou}
                        alt=""
                        className={
                          acLang === item.value
                            ? "lang_list_item_img_active"
                            : "lang_list_item_img"
                        }
                      />
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal
        open={isShowCheckIn}
        onCancel={checkInCancel}
        afterOpenChange={afterOpenChange}
        centered
        footer={null}
        closable={false}
        className="check_in_modal"
      >
        <div className="check_in_modal_tit">{t("Congratulations")}</div>
        <div className="check_in_modal_tit_bg"></div>
        {lang === "en" ? (
          <img src={CIS} alt="" className="check_in_CIS" />
        ) : (
          <img src={CISCN} alt="" className="check_in_CIS" />
        )}
        <div className="check_in_modal_get">
          <span className="check_in_modal_get_text">{t("Get")}</span>
          <span className="check_in_modal_get_num">{checkInNum} TMIR</span>
        </div>
        <div className="check_in_modal_bom">
          <div className="check_in_modal_crown_one"></div>
          <div className="check_in_modal_crown_two"></div>
          <div className="check_in_modal_crown_three"></div>

          <Link
            to="https://discord.com/invite/miraweb3"
            className="check_in_modal_link"
            target="_blank"
            onClick={checkInCancel}
          >
            {t("You can view it in 'My Account'")}
          </Link>
          <img src={juantwo} alt="" className="check_in_modal_juan_two" />
        </div>
        <img src={juanone} alt="" className="check_in_modal_juan_one" />
      </Modal>
    </div>
  );
};

export default Navbar;
